@import "../variable";

@mixin customHomeButton {
  // Button width
  .btn-cw {
    border-radius: 35px;
  }
  .btn-cw-260 {
    width: 260px;
    border-radius: 35px;
  }

  .btn-cw-250 {
    width: 250px;
    border-radius: 40px;
  }

  .btn-cw-204 {
    width: 204px;
    border-radius: 22px;
  }

  .btn-cw-150 {
    width: 150px;
    border-radius: 22px;
  }

  .btn-cw-170 {
    width: 170px;
    border-radius: 25px;
  }

  .btn-cw-120 {
    width: 120px;
    border-radius: 29px;
  }

  .btn-cw-110 {
    width: 110px;
    border-radius: 25px;
  }

  // Button height
  .btn-ch-70 {
    font-weight: 600;
    height: 70px;
  }

  .btn-ch-50 {
    height: 50px;
    font-size: 16px;
  }

  .btn-ch-44 {
    height: 44px;
  }

  .btn-ch-45 {
    height: 45px;
  }

  .btn-ch-38 {
    height: 38px;
    font-size:12px;
    font-weight: 300;
  }

  // Custom button class
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);

    .btn-text {
      position: absolute;
      font-size: 16px;
    }
    .ng-fa-icon {
      margin-right: 5px;
    }
  }

  .btn:disabled,
  .btn[disabled] {
    border: solid 2px $borderGrey !important;
    background-color: $bgDisable !important;
  }

  .btn.btn-lime {
    background-color: $bgLime;
    border: solid 2px $borderLime;
    color: $textWhite;
    &:hover,
    &:focus,
    &:active {
      color: $textWhite;
      background-color:$bgIndia;
    }
  }

  .btn.btn-black {
    background-color: $bgBlack;
    color: $textOfWhite;
    &:hover {
      color: $textWhite;
      background-color: $bgAegean;
    }
  }

  .btn-white {
    color: $textShadow;
    background-color: $bgOffWhite;
    border: solid 2px $bgBlack;
    &:hover,
    &:focus,
    &:active {
      color: #FFFFFF;
      background-color: #212529;
    }
  }

  @media screen and (max-width: 575px){
    .btn {
      margin: 10px auto;
      width: 100%;
    }
  }
}
