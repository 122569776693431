@import "./variable";
@import 'base/button';
@import 'base/text';

.guest__logo {
  height: 40px;
}
.home-page {
  font-weight: 600 !important;

  input {
    color: $textShadow !important;
  }
  label {
    .badge-custom {
      display: inline-block;
      margin-top: -4px;
      padding: 0 0.3rem;
      font-size: 15px;
      text-align: center;
      font-weight: 500;
      white-space: nowrap;
      vertical-align: middle;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .badge-danger {
      background: $red-color !important;
    }
  }

  .alert-common {
    background-color: #f4fafa;
    border-radius: 5px;
    border: 1px solid #e5efef;
  }

  ul.dropdown-menu {
    border: solid 1px $borderGrey;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    border-top: none;
    margin-top: 0 !important;
    li {
      border-top: solid 1px $borderGrey;

      &:nth-child(odd) {
        background: #ffff;
      }
      &:nth-child(even) {
        background: #f5f9fa;
      }

      &:first-child {
        border-top: none;
      }

      &:focus,
      &:active {
        background-color: transparent !important;
      }
    }
    .address-item{
      color: $textShadow;
    }
  }

  .btn.dropdown-toggle {
    &:focus,
    &:active {
      background-color: transparent !important;
      color: inherit;
      border-bottom-left-radius: 0% !important;
      border-bottom-right-radius: 0% !important;
    }

    &:hover {
      background-color: transparent !important;
      color: inherit;
    }
  }

  .page-title {
    color: $textShadow;
    margin: 20px 0 30px;
    font-size: 25px;
  }

  .text-shadow {
    color: $textShadow;
  }


  .body .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .body .card {
    @include customHomeButton;
    @include customHomeText;
    border: none;
    // Margin, padding custom class
    .c-mt-5 {
      margin-top: 50px !important;
    }

    .c-mt-4 {
      margin-top: 40px !important;
    }

    .c-mt-3 {
      margin-top: 30px;
    }

    .c-mt-2 {
      margin-top: 20px !important;
    }

    .c-mb-3 {
      margin-bottom: 30px;
    }

    .c-mb-5 {
      margin-bottom: 50px;
    }

    .c-mb-4 {
      margin-bottom: 40px;
    }

    .c-mb-2 {
      margin-bottom: 20px;
    }

    .c-ml-5 {
      margin-left: 50px;
    }

    .c-ml-4 {
      margin-left: 50px;
    }

    .c-ml-3 {
      margin-left: 30px;
    }

    // Custom card class
    .card-header {
      background-color: $bgBlack;
      height: 45px;
      color: $textWhite;
      font-size: 19px;
      display: flex;
      align-items: center;
      border: none;
      border-radius: 0;
      padding-left: 15px;
    }

    .card-body {
      border-radius: 0;
      padding: 0 40px;

      .session-title {
        font-size: 19px;
        // margin-top: 50px;
        color: $textShadow;
        font-weight: 600;
      }
    }

    // Base button
    .btn-default {
      align-items: center;
      font-size: 12px;
      color: $textWhite;
      background-color: $bgBlack;
      border: solid 2px $bgBlack;
      border-radius: 30px;
      &:hover,
      &:focus,
      &:active {
        color: $textBlack;
        background-color: $bgWhite;
      }
    }

    .btn-lg.btn-default {
      font-size: 18px;
      padding: 8px 40px;
    }

    .btn-shadow {
      box-shadow: 1px 2px 4px #ccc;
      -moz-box-shadow: 1px 2px 4px #ccc;
    }


    .text-common {
      color: $textBlack
    }

    .text-success-common {
      color: $bgLime;
    }

    .section {
      h5.title {
        color: $textShadow;
        font-weight: 700;
        margin: 20px 0;
      }

      h6.title {
        color: $textShadow;
        font-weight: 600;
        font-size: 19px;
      }

      h6.heading {
        width: 100%;
        font-size: 19px;
        color: $textShadow;
        font-weight: 600;
        margin: 20px 0;
        padding: 4px 10px;
        border-left: 8px solid $bgBlack;
      }
    }

    // Custom input
    .c-input {
      box-shadow: none;
      border: solid 2px $borderGrey;
      border-radius: 5px;
      height: 40px;
      font-size: 19px;
    }
  }
}

.construction-body {
  .modal-content {
    width: 300px;
    margin: 0 auto;
  }
}

.terms-custom-modal .modal-content {
  border-radius: 20px;
  margin: 0 auto;
}

@media screen and (max-width: 575px) {
  .terms-custom-modal .modal-content {
    width: 90% !important;
  }
}
.home-ff-16 {
  font-size: 16px !important;
}

.home-ff-14 {
  font-size: 14px !important;
}

.page-header__content{
  min-height: 40px;
  display: flex;
}
.page-footer__content {
  min-height: 40px;
  padding: 8px 0;
}
.nav-align-items {
  align-items: center;
}
.footer-copyright {
  .first-span {
    width: 16%;
  }
  a {
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
    text-underline-offset: 2px;
  }
}

@media screen and (max-width: 1060px) {
  .footer-copyright {
    justify-content: center !important;
    .home-ff-14 {
      text-align: center;
    }
    .first-span {
      display: none;
    }
  }
}

.payment-method-explanatory {
  font-weight: 500;
  box-shadow: none;
  border-radius: 5px;
  width: 100%;
  height: fit-content;
  font-size: 16px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #f5f5f5;
}

@media screen and (max-width: 575px) {
  body .modal-content {
    min-width: 320px;
  }
}
