.bs-datepicker-body {
  min-height: auto !important;
  table tbody tr td .is-other-month {
    display: none;
  }
}

.bg-blue-pastel {
	background-color: #2d84bb !important;
}

#body .content .page-title h3 {
	margin: 1rem 0;
	font-size: 18px;
	font-weight: 700;
}

#body .content.page-title {
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.55555;
	font-weight: 700;
	color: #444;

	.active {
		color: #444;
	}
}

.wrapper table thead th {
  font-weight: 500 !important;
}

.wrapper .modal-body .form-group span,label {
	font-weight: 500 !important;
}

.modal-header .modal-title {
	font-size: 18px;
	font-weight: bold;
}

.wrapper .card .card-header {
	font-weight: 500;
}

.w-16 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  padding: 0 5px;
}

.w-33 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  padding: 0 5px;
}

.w-50 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 5px;
}

.w-66 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  padding: 0 5px;
}

.w-83 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  padding: 0 5px;
}

.contract-terms .modal-content, .contract-group .modal-content {
  min-width: 800px;
}

.modal-content {
  min-width: 500px;
}
