@import "../_variable";

@mixin customHomeText {
  // Font size
  .c-fs-16 {
    font-size: 16px !important;
  }
  .c-fs-18 {
    font-size: 18px !important;
  }

  .c-fs-19 {
    font-size: 19px !important;
  }

  .c-fs-14 {
    font-size: 14px !important;
  }

  .c-fs-21 {
    font-size: 21px !important;
  }

  .c-fs-22 {
    font-size: 22px !important;
  }

  .c-fs-25 {
    font-size: 25px !important;
  }

  .c-fs-30 {
    font-size: 30px !important;
  }

  // Font weight
  .c-fw-5 {
    font-weight: 500;
  }

  .c-fw-6 {
    font-weight: 600 !important;
  }
  .c-fw-3 {
    font-weight: 300 !important;
  }
  .c-fw-7 {
    font-weight: 700;
  }

  // Font color
  .text-white {
    color: $textWhite;
  }

  .text-lime {
    color: $textLime;
  }

  .text-green {
    color: $textGreen;
  }

  .text-cloud {
    color: $textCloud;
  }

  .text-red {
    color: $textRed;
  }

  .text-disable {
    color: $textDisable;
  }

  .c-ff-h6 {
    font-weight: 600;
  }

  .c-ff-h3 {
    font-weight: 300;
  }
}
