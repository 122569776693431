/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

@import "scss/variable";
@import "scss/base/common";
@import "scss/home";
@import "scss/admin";
